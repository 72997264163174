<template>
  <section class="main-container">
    <div class="container">
      <div class="row">
        <transition name="fade" mode="out-in">
          <service-content
            :key="currentService.slug"
            :service="currentService"
          />
        </transition>
        <transition name="fade" mode="out-in">
          <service-side-bar
            :key="currentService.slug"
            :service="currentService"
          />
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import ServiceContent from "../components/ServiceContent.vue";
import ServiceSideBar from "../components/ServiceSideBar.vue";
import { data } from "../data/data.js";

const services = data.services;

export default {
  components: { ServiceContent, ServiceSideBar },
  metaInfo() {
    return {
      title: this.currentService.title,
      meta: [
        {
          name: "description",
          content: this.currentService.metaDesc
        },
        { property: "og:title", content: this.currentService.title },
        {
          property: "og:site_name",
          content: "Jülide İrgin - Aile ve Çift Terapisi"
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: this.fullUrl
        },
        {
          property: "og:image",
          content: this.currentService.servicesPageImg
        },
        {
          property: "og:description",
          content: this.currentService.shortDesc
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:site",
          content: this.fullUrl
        },
        { name: "twitter:title", content: this.currentService.title },
        {
          name: "twitter:description",
          content: this.currentService.shortDesc
        },
        {
          name: "twitter:image:src",
          content: this.currentService.servicesPageImg
        }
      ]
    };
  },
  data() {
    return {
      currentService: {},
      fullUrl: ""
    };
  },
  mounted() {
    if (window) {
      this.fullUrl = window.location.href.toString();
    }
  },
  created() {
    this.currentService = this.getCurrentService();

    if (this.$ssrContext && this.$ssrContext.fullUrl) {
      this.fullUrl = this.$ssrContext.fullUrl;
    }
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  beforeRouteUpdate(to, from, next) {
    const serviceExists = this.checkServiceExists(to.params.service_slug);
    if (serviceExists) {
      this.currentService = this.getCurrentService(to.params.service_slug);
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  methods: {
    checkServiceExists(service_slug) {
      if (!service_slug || service_slug === "") {
        return false;
      }

      const filteredService = services.filter(service => {
        return service.slug === service_slug;
      });

      if (!filteredService || filteredService.length === 0) {
        return false;
      }

      return true;
    },
    getCurrentService(slug) {
      const service_slug = slug || this.$route.params.service_slug;

      const filteredService = services.filter(service => {
        return service.slug === service_slug;
      });
      const currentService = filteredService[0];

      return currentService;
    }
  }
};
</script>

<style></style>
