<template>
  <div
    class="main col-lg-8 service-content"
    itemscope
    itemtype="http://schema.org/Service"
  >
    <h1 class="title page-title" itemprop="name">{{ service.title }}</h1>
    <div class="separator-2"></div>
    <service-content-paragraph
      v-for="(item, index) in service.content"
      :key="index"
      :service-content="item"
    />
  </div>
</template>

<script>
import ServiceContentParagraph from "./ServiceContentParagraph.vue";

export default {
  components: { ServiceContentParagraph },
  props: {
    service: {
      type: Object,
      reqired: true,
      default: function() {
        return {};
      }
    }
  }
};
</script>
