<template>
  <div class="service-content-paragraph" itemprop="description">
    <h1 v-if="serviceContent.subTitle" class="title">
      {{ serviceContent.subTitle }}
    </h1>
    <div v-if="serviceContent.subTitle" class="separator-2"></div>
    <p v-if="serviceContent.contentInfo.type === 'text'">
      {{ serviceContent.contentInfo.content }}
    </p>
    <ul v-if="serviceContent.contentInfo.type === 'list'">
      <li
        v-for="(item, index) in serviceContent.contentInfo.content"
        :key="index"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    serviceContent: {
      type: Object,
      reqired: true,
      default: function() {
        return {};
      }
    }
  }
};
</script>
