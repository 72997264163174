<template>
  <aside class="col-lg-4 col-xl-3 ml-xl-auto">
    <div class="sidebar">
      <div class="block clearfix">
        <img :src="service.mainPageImg" />
      </div>
      <div class="block clearfix">
        <h3 class="title">Hizmetler</h3>
        <div class="separator-2"></div>
        <nav>
          <ul
            itemscope
            itemtype="https://schema.org/SiteNavigationElement"
            role="menu"
            class="nav flex-column"
          >
            <li
              v-for="(item, index) in slugs"
              :key="index"
              itemprop="name"
              role="menuitem"
              class="nav-item"
            >
              <router-link
                class="nav-link"
                active-class="active"
                itemprop="url"
                :to="item.slug"
                :alt="item.title"
                >{{ item.title }}</router-link
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </aside>
</template>

<script>
import { data } from "../data/data.js";
const services = data.services;

export default {
  props: {
    service: {
      type: Object,
      reqired: true,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      slugs: []
    };
  },
  mounted() {
    this.slugs = services.map(service => {
      return { slug: `/hizmetler/${service.slug}`, title: service.title };
    });
  }
};
</script>
